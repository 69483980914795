import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import { rem } from "polished"

import { StoreContext } from "../store"

import Meta from "../components/meta"
import Row from "../components/grid/row"
import Col from "../components/grid/column"
import Hero from "../components/common/full-width-hero"
import Container from "../components/container"
import Spacer from "../components/spacer"
import { getMarkdownRemarkContent } from "../utils/markdown-remark-content"
import { getMetaKeywords } from "../utils/meta-keywords-from-array"

const Content = styled.div.attrs({ className: `styled-h4` })`
  p:not(:first-of-type),
  h3,
  ul {
    margin-top: ${rem(30)};
  }
  h3 {
    font-weight: bold;
  }
  ul {
    list-style-type: disc;
    padding-left: ${rem(30)};
  }
  a {
    text-decoration: underline;
  }
  address {
    white-space: pre-line;
  }
`

class CareersPageContent extends React.Component {
  static contextType = StoreContext

  componentDidMount() {
    this.context.setHeaderTheme(`bright`)
  }

  render() {
    const terms =
      this.props.data.terms.nodes.length > 0
        ? this.props.data.terms.nodes[0]
        : {}
    const content = {
      meta: {
        title: terms.metaTitle,
        description: terms.metaDescription ?? ``,
        keywords: getMetaKeywords(terms.metaKeywords),
      },
      heroBlock: {
        title: terms.pageTitle,
        content: ``,
        /* c8 ignore next 3 */
        bgColor: (props) => props.theme.colorWode,
        textColor: (props) => props.theme.colorWhite,
      },
      article: {
        content: getMarkdownRemarkContent(terms.policyContent),
      },
    }

    return (
      <React.Fragment>
        <Meta {...content.meta} />

        <Hero {...content.heroBlock} />

        <Container>
          <Spacer top bottom>
            <Row>
              <Col>
                <Content
                  dangerouslySetInnerHTML={{
                    __html: content.article.content,
                  }}
                />
              </Col>
            </Row>
          </Spacer>
        </Container>
      </React.Fragment>
    )
  }
}

CareersPageContent.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.any,
}

const CareersPage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        terms: allContentfulTermsOfUsePage(
          filter: { navigationUrl: { eq: "/terms" } }
          limit: 1
        ) {
          distinct(field: navigationUrl)
          nodes {
            metaTitle
            pageTitle
            policyContent {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `}
    render={(data) => <CareersPageContent data={data} {...props} />}
  />
)

export default CareersPage
